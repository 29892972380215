<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">
          <h2 class="text-xl font-medium mr-auto border-b pb-5">
            <app-i18n code="generalAssembly.form.add" v-if="isEditing"></app-i18n>
            <app-i18n code="generalAssembly.form.add" v-else></app-i18n>
          </h2>
          <section class="relative">
            <div class="flex justify-center" style="width: 100%; height: 80vh" v-if="findLoading || !model">
              <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
            </div>
            <form v-if="model">
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4 class="
                    text-lg
                    font-medium
                    leading-none
                    items-center
                    mr-2
                    lg:col-span-2
                  ">
                  {{ i18n('generalAssembly.table.title') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <input id="title-en" type="text" class="
                      form-control
                      w-full
                      dark:bg-dark-9 dark:placeholder-white
                    " v-model="model.title.en" :placeholder="i18n('library.fields.english')" />
                </div>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2" :class="isRTL ? 'lg:pr-5' : 'lg:pl-5'">
                  <input id="title-ar" type="text" class="
                      form-control
                      w-full
                      dark:bg-dark-9 dark:placeholder-white
                    " v-model="model.title.ar" :placeholder="i18n('library.fields.arabic')" />
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4 class="
                    text-lg
                    font-medium
                    leading-none
                    items-center
                    mr-2
                    lg:col-span-2
                  ">
                  {{ i18n('library.fields.description') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <textarea id="description-en" :rows="4" class="
                      form-control
                      w-full
                      dark:bg-dark-9 dark:placeholder-white
                    " v-model="model.description.en" :placeholder="i18n('library.fields.english')"></textarea>
                </div>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2" :class="isRTL ? 'lg:pr-5' : 'lg:pl-5'">
                  <textarea id="description-ar" :rows="4" class="
                      form-control
                      w-full
                      dark:bg-dark-9 dark:placeholder-white
                    " v-model="model.description.ar" :placeholder="i18n('library.fields.arabic')"></textarea>
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4 class="
                    text-lg
                    font-medium
                    leading-none
                    items-center
                    mr-2
                    lg:col-span-2
                  ">
                  {{ i18n('common.status') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <input
                    id="title-en"
                    type="checkbox"
                    class="cursor-pointer"
                    v-model="model.status"
                    style="width: 20px; height: 20px; border-radius: 50%; accent-color: #2D3748"
                  />
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4 class="
                    text-lg
                    font-medium
                    leading-none
                    items-center
                    mr-2
                    lg:col-span-2
                  ">
                  {{ i18n('generalAssembly.table.pdf') }}
                </h4>
                <div class="lg:col-span-8 col-span-12 lg:mt-0 mt-2">
                  <div class="relative" v-if="isEditing && bannerUrl">
                    <!-- Display  pdf using iframe -->
                    <iframe :src="bannerUrl"  style="width: 100%; height: 350px;border:3px solid #2D3748"/>
                    <img src="/images/deleteIcon.png" alt="" class="
                        delete-icon
                        w-12
                        h-12
                        absolute
                        bottom-0
                        left-0
                        cursor-pointer
                      " @click="model.pdf.publicUrl = undefined" />
                  </div>
                  <Uploader ref="uploaderBanner" v-else></Uploader>
                </div>
              </div>
              <div class="flex justify-center items-center mt-10">
                <AppButton type="button" class="btn bg-theme-36 text-white cursor-pointer"
                  :class="!saveLoading ? 'w-24' : ''" :disabled="saveLoading || findLoading || uploadLoading"
                  :loading="saveLoading" @click="doSubmit">
                  <strong>{{ i18n('common.save') }}</strong>
                  <template v-slot:loading>
                    <app-i18n code="common.loading"></app-i18n>
                    <LoadingIcon icon="three-dots" color="#FFFFFF" style="margin: 0 4px" />
                  </template>
                </AppButton>
                <button type="button" class="
                    btn
                    bg-theme-31
                    text-black
                    w-24
                    ml-3
                    mr-3
                    cursor-pointer
                  " :disabled="saveLoading || findLoading || uploadLoading" @click="doCancel">
                  <app-i18n code="common.cancel"></app-i18n>
                </button>
              </div>
            </form>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import Message from '@/shared/message/toastify'
import Uploader from '@/views/library/uploader.vue'

export default defineComponent({
  props: {
    id: {
      type: String,
      required: false
    }
  },
  components: {
    Uploader
  },
  data() {
    return {
      model: null,
      errorMessage: null
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      record: 'generalAssembly/form/record',
      findLoading: 'generalAssembly/form/findLoading',
      saveLoading: 'generalAssembly/form/saveLoading'
    }),
    labelLocalized() {
      return this.isRTL ? 'nameAr' : 'nameEn'
    },
    isEditing() {
      return !!this.id
      // return true
    },
    filesToSaveBanner() {
      return this.$refs.uploaderBanner?.filesToSave || []
    },
    bannerUrl() {
      return this.model && this.model.pdf?.publicUrl ? this.model.pdf.publicUrl : null
    }
  },
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.generalAssembly')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.generalAssembly')
    }
  },
  async mounted() {
    if (this.isEditing) {
      await this.doFind(this.id)
      console.log(this.record)
    } else {
      this.doNew()
    }
    this.model = this.record || {
      title: {
        en: '',
        ar: ''
      },
      description: {
        en: '',
        ar: ''
      },
      status: true,
      pdf: {
        publicUrl: ''
      }
    }
    if (this.isEditing) {
      this.model.name = this.record.name
      this.model.description = this.record.description
      this.model.status = this.record.status === 'ACTIVE'
      this.model.pdf = this.record.pdf
    }
    console.log(this.model)
  },
  methods: {
    ...mapActions({
      doNew: 'generalAssembly/form/doNew',
      doFind: 'generalAssembly/form/doFind',
      doCreate: 'generalAssembly/form/doCreate',
      doUpdate: 'generalAssembly/form/doUpdate'
    }),
    deleteImage(index) {
      this.model.images.splice(index, 1)
    },
    i18n(key, args) {
      return this.$t(key, args)
    },
    isFormValid() {
      if (this.model.title.en.length == 0 || this.model.title.ar.length == 0) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }

      if (
        this.model.description.en.length == 0 ||
        this.model.description.ar.length == 0
      ) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }
      if (this.isEditing) {
        if (!(this.bannerUrl || this.filesToSaveBanner.length !== 0)) {
          this.errorMessage = 'restaurants.edit.errors.emptyFields'
          return false
        }
      } else {
        if (
          this.filesToSaveBanner.length === 0
        ) {
          this.errorMessage = 'restaurants.edit.errors.emptyFields'
          return false
        }
      }
      return true
    },
    doCancel() {
      // this.$router.back()
      this.$router.push('/general-assembly')
    },
    doReset() {
      // this.model = formSchema.initialValues(this.record)
    },
    async doSubmit() {
      if (!this.isFormValid()) {
        const ERROR = this.i18n(this.errorMessage)
        return Message.error(ERROR)
      }

      // const { id, ...values } = formSchema.cast(this.model)
      const { title, description, status } =
        this.model
      if (this.isEditing) {
        await this.doUpdate({
          id: this.id,
          title,
          description,
          status: status ? 'ACTIVE' : 'INACTIVE',
          pdf: {
            publicUrl: this.bannerUrl || this.filesToSaveBanner[0]
          }
        })
      } else {
        await this.doCreate({
          title,
          description,
          status: status ? 'ACTIVE' : 'INACTIVE',
          pdf: {
            publicUrl: this.filesToSaveBanner[0]
          }
        })
      }
    }
  }
})
</script>

<style lang="scss">
.dropzone {
  .dz-preview {
    .dz-details {
      font-size: 12px !important;
      padding: 0em 1em !important;
    }

    .dz-image {
      width: 80px !important;
      height: 80px !important;
    }
  }
}
</style>
