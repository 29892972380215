// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyB-ZcFUbmQNNAWcE4dGJ5T89OZP6eYLCAk',
  authDomain: 'smouha-club.firebaseapp.com',
  databaseURL: 'https://smouha-club-default-rtdb.firebaseio.com',
  projectId: 'smouha-club',
  storageBucket: 'smouha-club.appspot.com',
  messagingSenderId: '743241934871',
  appId: '1:743241934871:web:e8ef1fdf83d6a60cefd452',
  measurementId: 'G-H7DT91Y8F8'
}

const REGION = 'europe-west3'
const BASE_URL = `https://${REGION}-${firebaseConfig.projectId}.cloudfunctions.net`

const backendUrl = BASE_URL
const backendUrlAuth = `${backendUrl}/auth`
const backendUrlIam = `${backendUrl}/iam`
const backendUrlBranch = `${backendUrl}/branch`
const backendUrlRestaurant = `${backendUrl}/restaurant`
const backendUrlClinic = `${backendUrl}/clinic`
const backendUrlLibrary = `${backendUrl}/library`
const backendUrlKindergarten = `${backendUrl}/kindergarten`
const backendUrlSponsor = `${backendUrl}/sponsor`
const backendUrlSocialMedia = `${backendUrl}/socialMedia`
const backendUrlNews = `${backendUrl}/news`
const backendUrlArticle = `${backendUrl}/article`
const backendUrlOffers = `${backendUrl}/offers`
const backendUrlSports = `${backendUrl}/sports`
const backendUrlSettings = `${backendUrl}/settings`
const backendUrlTrips = `${backendUrl}/trips`
const backendUrlServiceCenter = `${backendUrl}/serviceCenter`
const backendUrlSportsActivities = `${backendUrl}/sportsActivities`
const backendUrlAuction = `${backendUrl}/auction`
const backendUrlTelecom = `${backendUrl}/telecom`
const backendUrlActivity = `${backendUrl}/activity`
const backendUrlAds = `${backendUrl}/ads`
const backendUrlMedia = `${backendUrl}/album`
const backendUrlCategory = `${backendUrl}/category`
const backendUrlLive = `${backendUrl}/live`
const backendUrlDiscounts = `${backendUrl}/discounts`
const backendUrlUser = `${backendUrl}/admin`
const backendUrlAdministration = `${backendUrl}/administration`
const backendUrlCultural = `${backendUrl}/cultural`
const backendUrlParking = `${backendUrl}/parking`
const backendUrlLogs = `${backendUrl}/auditLog`
const backendUrlForms = `${backendUrl}/forms`
const backendUrlAssembly = `${backendUrl}/generalAssembly`

export default {
  firebaseConfig,
  REGION,
  BASE_URL,
  backendUrlAuth,
  backendUrlIam,
  backendUrlBranch,
  backendUrlRestaurant,
  backendUrlClinic,
  backendUrlLibrary,
  backendUrlKindergarten,
  backendUrlSponsor,
  backendUrlSocialMedia,
  backendUrlNews,
  backendUrlArticle,
  backendUrlOffers,
  backendUrlSports,
  backendUrlSettings,
  backendUrlTrips,
  backendUrlServiceCenter,
  backendUrlSportsActivities,
  backendUrlTelecom,
  backendUrlActivity,
  backendUrlAds,
  backendUrlAuction,
  backendUrlMedia,
  backendUrlCategory,
  backendUrlLive,
  backendUrlDiscounts,
  backendUrlAdministration,
  backendUrlCultural,
  backendUrlParking,
  backendUrlUser,
  backendUrlLogs,
  backendUrlForms,
  backendUrlAssembly
}
