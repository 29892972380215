/* eslint-disable */
import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
const graphqlClient = GraphqlClient.initApolloClient(config.backendUrlAssembly)

export class GeneralAssemblyService {
  static async list(filter, pagination) {
    const response = await graphqlClient.query({
      query: gql`
        query LIST(
    $filter: GeneralAssemblyFilterInput, 
    $orderBy: String,
    $pagination: PaginationInput
) {
    generalAssemblyList(
        filter: $filter, orderBy: $orderBy, pagination: $pagination
    ) {
        pagination {
            isFirstPage
            isLastPage
        }
        count
        rows {
            id
            title {
                en
                ar
            }
            description {
                en
                ar
            }
            pdf {
                publicUrl
            }
            status

            createdAt
            createdBy
            updatedAt
            updatedBy
        }
    }
}
      `,

      variables: {
        filter,
        orderBy: 'createdAt',
        pagination
      }
    })

    return response.data.generalAssemblyList
  }

  static async find(id) {
    const response = await graphqlClient.query({
      query: gql`
       query FIND($id: String!) {
        generalAssemblyFind(id: $id) {
            id
            title {
                en
                ar
            }
            description {
                en
                ar
            }
            pdf {
                publicUrl
            }
            status
            createdAt
            createdBy
            updatedAt
            updatedBy
        }
      }
      `,
      variables: {
        id
      }
    })

    return response.data.generalAssemblyFind
  }

  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation CREATE($data: GeneralAssemblyInput!) {
          generalAssemblyCreate(data: $data) {
            id
          }
        }
      `,
      variables: {
        data
      }
    })
    return response.data.generalAssemblyCreate
  }

  static async update(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation UPDATE($id: String!, $data: GeneralAssemblyInput!) {
          generalAssemblyUpdate(id: $id, data: $data) {
            id
          }
        }
      `,
      variables: {
        id,
        data
      }
    })
    return response.data.generalAssemblyUpdate
  }

  static async delete(id) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation DESTROY($id: String!) {
          generalAssemblyDestroy(id: $id)
        }
      `,
      variables: {
        id
      }
    })
    return response.data.generalAssemblyDestroy
  }
}
