/* eslint-disable */

import generalAssemblyListStore from '@/store/general-assembly/general-assembly-list-store'
import generalAssemblyViewStore from '@/store/general-assembly/general-assembly-view-store'
import generalAssemblyFormStore from '@/store/general-assembly/general-assembly-form-store'

export default {
  namespaced: true,
  modules: {
    list: generalAssemblyListStore,
    form: generalAssemblyFormStore,
    view: generalAssemblyViewStore
    // destroy: generalAssemblyDestroyStore
  }
}
