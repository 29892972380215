<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium">{{ i18n('generalAssembly.title') }}</h2>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box dark:bg-dark-8 p-5 sm:mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start justify-end">
        <div class="flex mt-5 sm:mt-0">
          <button class="btn btn-primary sm:mt-2 mx-3 w-full sm:w-28 p-1" @click="createGeneralAssembly">
            <PlusIcon />
            {{ i18n('common.create') }}
          </button>
        </div>
      </div>
      <loading-spinner type="half-circle" :duration="1000" :size="40" color="#4469A8" :loading="loading || !records" />
      <div class="overflow-x-auto g">
        <table class="table table-report sm:mt-2 hidden md:table">
          <thead>
            <tr>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">#</th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('generalAssembly.table.title') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('generalAssembly.table.description') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('common.status') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('common.table.actions') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in records" :key="index">
              <td class="w-40">
                <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                  #{{ index + 1 }}
                </div>
              </td>
              <td>
                <div class="font-medium whitespace-nowrap truncate text-center">
                  {{ presenterLocalization(row, 'title') }}
                </div>
              </td>
              <td class="text-center w-72">
                <div class="font-medium whitespace-nowrap cursor-pointer w-72 truncate">
                  {{ presenterLocalization(row, 'description') }}
                </div>
              </td>
              <td>
                <div class="font-medium whitespace-nowrap truncate text-center">
                  <div class="flex items-center justify-center" :style="{
                    color: row.status === 'ACTIVE' ? 'green' : 'red'
                  }">
                    <XSquareIcon v-if="!row.status === 'ACTIVE'" class="w-4 h-4 mr-2 ml-2" />
                    <CheckSquareIcon v-else class="w-4 h-4 mr-2 ml-2" />
                    {{
                      row.status === 'ACTIVE'
                        ? i18n('common.activated')
                        : i18n('common.disabled')
                    }}
                  </div>
                </div>
              </td>
              <td class="table-report__action w-56">
                <div class="flex justify-center items-center gap-3 text-theme-14 dark:text-white cursor-pointer">
                  <!-- <div @click="viewGeneralAssembly(row.id)">
                    <EyeIcon class="w-4 h-4 mr-1" />
                  </div> -->
                  <div @click="editGeneralAssembly(row.id)">
                    <EditIcon class="w-4 h-4 mr-1" />
                  </div>
                  <a href="javascript:;" data-toggle="modal" data-target="#delete-modal-preview" class="text-theme-24"
                    @click="deleteId = row.id">
                    <Trash2Icon class="w-4 h-4 mr-1" />
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="mobile-table md:hidden mt-8">
          <mobile-table-comp v-for="(row, index) in rows" :key="index" :row="row" class="md:hidden">
            <div class="field">
              <span>{{ i18n('generalAssembly.table.title') }} </span>
              <div class="font-medium whitespace-nowrap truncate text-center">
                {{ presenterLocalization(row, 'title') }}
              </div>
            </div>
            <div class="field">
              <span> {{ i18n('generalAssembly.table.description') }} :</span>
              <div class="font-medium whitespace-nowrap cursor-pointer truncate">
                {{ presenterLocalization(row, 'description') }}
              </div>
            </div>
            <div class="field">
              <span> {{ i18n('common.status') }} :</span>
              <div class="font-medium whitespace-nowrap truncate text-center">
                <div class="font-medium whitespace-nowrap truncate text-center">
                  <div class="flex items-center justify-center" :style="{
                    color: row.status === 'ACTIVE' ? 'green' : 'red'
                  }">
                    <XSquareIcon v-if="!row.status === 'ACTIVE'" class="w-4 h-4 mr-2 ml-2" />
                    <CheckSquareIcon v-else class="w-4 h-4 mr-2 ml-2" />
                    {{
                      row.status === 'ACTIVE'
                        ? i18n('common.activated')
                        : i18n('common.disabled')
                    }}
                  </div>
                </div>
              </div>
            </div>
            <div class="field">
              <span> {{ i18n('common.table.actions') }} :</span>
              <div class="flex justify-center items-center gap-3 text-theme-14 dark:text-white cursor-pointer">
                <div @click="viewGeneralAssembly(row.id)">
                  <EyeIcon class="w-4 h-4 mr-1" />
                </div>
                <div @click="editGeneralAssembly(row.id)">
                  <EditIcon class="w-4 h-4 mr-1" />
                </div>
                <a href="javascript:;" data-toggle="modal" data-target="#delete-modal-preview" class="text-theme-24"
                  @click="deleteId = row.id">
                  <Trash2Icon class="w-4 h-4 mr-1" />
                </a>
              </div>
            </div>
          </mobile-table-comp>
        </div>
        <app-empty-page v-if="!rows.length && !loading" :label="i18n('empty.parking')" label-class="text-lg font-medium"
          icon-size="4x" class="box w-full py-64"></app-empty-page>
      </div>
      <Pagination v-if="rows.length" :page="currentPage" :pageSize="pagination.limit" :rowsNumber="pagination.total"
        :showSizeChanger="true" :isFirstPage="isFirstPage" :isLastPage="isLastPage" :disabled="!rows.length"
        position="center" @next-page="doNextPage" @prev-change="doPrevPage" @size-change="doChangePaginationPageSize">
      </Pagination>
    </div>
    <!-- END: HTML Table Data -->
    <DeleteModal item="generalAssembly" @delete="doDestroy(deleteId)" />
  </div>
</template>

<script>
import DeleteModal from '@/components/modals/delete-modal.vue'
import { mapActions, mapGetters } from 'vuex'
import { GenericModel } from '@/shared/model/generic-model'

export default {
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.generalAssembly')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.generalAssembly')
    },
    rows() {
      this.records = this.rows
    }
  },
  components: {
    DeleteModal
  },
  data() {
    return {
      records: []
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      rows: 'generalAssembly/list/rows',
      currentPage: 'generalAssembly/list/currentPage',
      isFirstPage: 'generalAssembly/list/isFirstPage',
      isLastPage: 'generalAssembly/list/isLastPage',
      loading: 'generalAssembly/list/loading',
      pagination: 'generalAssembly/list/pagination'
    })
  },
  async mounted() {
    await this.doFetchFirstPage()
    console.log(this.rows)
    this.records = this.rows
  },
  methods: {
    ...mapActions({
      doFetch: 'generalAssembly/list/doFetch',
      doFetchNextPage: 'generalAssembly/list/doFetchNextPage',
      doFetchPreviousPage: 'generalAssembly/list/doFetchPreviousPage',
      doChangePaginationCurrentPage:
        'generalAssembly/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'generalAssembly/list/doChangePaginationPageSize',
      doDestroy: 'generalAssembly/list/doDestroy'
    }),
    async doFetchFirstPage() {
      await this.doChangePaginationCurrentPage(1)
      await this.doFetch({
        filter: {},
        pagination: {
          limit: 10,
          sortBy: 'asc'
        }
      })
    },
    async doNextPage() {
      await this.doFetchNextPage()
    },
    async doPrevPage() {
      await this.doFetchPreviousPage()
    },
    editGeneralAssembly(id) {
      this.$router.push(`/general-assembly-form-page/${id}`)
    },
    viewGeneralAssembly(id) {
      this.$router.push(`/general-assembly/${id}`)
    },
    createGeneralAssembly() {
      this.$router.push('/general-assembly-form-page')
    },
    i18n(key, args) {
      return this.$t(key, args)
    },
    presenter(row, fieldName) {
      return GenericModel.presenter(row, fieldName)
    },
    presenterLocalization(row, fieldName) {
      return GenericModel.presenterLocalization(row, fieldName)
    },
    presenterArr(row, fieldName) {
      return row[fieldName] && row[fieldName].length > 0
        ? row[fieldName].join(', ')
        : 'ـــ'
    },
    presenterArrLocalized(row, fieldName) {
      const locale = this.isRTL ? 'ar' : 'en'
      return row[fieldName] && row[fieldName].length > 0
        ? row[fieldName].map((val) => val[locale]).join(',')
        : 'ـــ'
    }
  }
}
</script>
<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}

.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}

.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>
