import { createStore } from 'vuex'
import main from './main'
import sideMenu from './side-menu'
import layout from './layout-store'
import auth from './auth/auth-store'
import user from './user/user-store'
import branch from '@/store/branch/branch-store'
import restaurant from '@/store/restaurant/restaurant-store'
import clinic from '@/store/clinic/clinic-store'
import library from '@/store/library/library-store'
import kindergarten from '@/store/kindergarten/kindergarten-store'
import sponsor from '@/store/sponsor/sponsor-store'
import socialMedia from '@/store/social-media/social-media-store'
import discount from '@/store/discount/discount-store'
import auction from '@/store/auction/auction-store'
import telecom from '@/store/telecom/telecom-store'
import activity from '@/store/activity/activity-store'
import ads from '@/store/ads/ads-store'
import logs from '@/store/logs/logs-store'
import media from '@/store/media/media-store'
import category from '@/store/category/category-store'
import live from '@/store/live/live-store'
import article from '@/store/article/article-store'
import parking from '@/store/parking/parking-store'
import generalAssembly from '@/store/general-assembly/general-assembly-store'

import contactUs from '@/store/contactUs/contactus-store'
import administration from '@/store/administration/administration-store'
import cultural from '@/store/culturalService/culturalService-store'

const store = createStore({
  modules: {
    main,
    sideMenu,
    layout,
    user,
    auth,
    branch,
    restaurant,
    clinic,
    library,
    kindergarten,
    sponsor,
    socialMedia,
    telecom,
    live,
    category,
    media,
    ads,
    logs,
    activity,
    auction,
    discount,
    parking,
    article,
    cultural,
    administration,
    contactUs,
    generalAssembly
  }
})

export function useStore() {
  return store
}

export default store
